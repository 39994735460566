import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { IoIosArrowForward } from "react-icons/io";
import LazyLoad from "react-lazyload";
import ReactPlayer from "react-player";
import VideoIcon from "../images/video.svg";

const ExploreSection = ({
	heading,
	content,
	btnCta1,
	btnCta2,
	image,
	video,
	videoUrl,
}) => {
	const [showPlayBtn, setShowPlayBtn] = useState(true);

	const vidRef = useRef(null);
	const handlePlayVideo = () => {
		vidRef.current.play();
		setShowPlayBtn(!showPlayBtn);
	};

	return (
		<section className="py-5 py-me-8 explore-section">
			<Container>
				<Row>
					<Col lg={6} className="mb-4 mb-lg-0 text-start pe-lg-6">
						<h2 className="my-0 pt-2 text-light-black">{heading}</h2>
					</Col>
					<Col lg={6}>
						<div
							className="pb-4 faq-wrapper text-light-black"
							dangerouslySetInnerHTML={{ __html: content }}
						></div>
						{((btnCta1 && btnCta1.url) || (btnCta2 && btnCta2.url)) && (
							<div className="d-flex align-items-lg-center flex-column flex-md-row">
								{btnCta1 && btnCta1.url && (
									<Button
										as={Link}
										href={btnCta1.url}
										size="lg"
										className="px-4 py-3 me-md-3 montserrat-bold fs-6 mb-3 mb-md-0 text-light-black"
										target={btnCta1.target}
										variant="secondary"
									>
										{btnCta1.title}
									</Button>
								)}
								{btnCta2 && btnCta2.url && (
									<Button
										as={Link}
										href={btnCta2.url}
										size="lg"
										className="px-4 py-3 montserrat-bold fs-6 bg-transparent text-light-black border-light-black"
										target={btnCta2.target}
									>
										{btnCta2.title}
										<IoIosArrowForward className="fs-4 ms-2" />
									</Button>
								)}
							</div>
						)}
					</Col>
				</Row>

				<Row className="mt-6 position-relative">
					<Col lg={12}>
						{image && image.sourceUrl ? (
							<div className="position-relative">
								<GatsbyImage
									image={image?.localFile.childImageSharp.gatsbyImageData}
									alt={image?.altText}
									className="rounded-5 main-image"
									style={{ width: "100%" }}
								/>
							</div>
						) : (
							<>
								{video && !videoUrl && (
									<LazyLoad height={200} offset={100}>
										<div>
											{" "}
											<video
												ref={vidRef}
												width="100%"
												controls
												height="auto"
												className="rounded-5 object-fit-cover"
											>
												<source src={video} type="video/mp4" />
											</video>
											{showPlayBtn && (
												<VideoIcon
													onClick={handlePlayVideo}
													className="position-absolute mx-auto h-100 start-0 end-0 top-0 bottom-0"
												/>
											)}
										</div>
									</LazyLoad>
								)}
								{videoUrl && (
									<LazyLoad height={200} offset={100}>
										<div
											style={{ overflow: "hidden" }}
											className="rounded-5 object-fit-cover video-height-new "
										>
											<ReactPlayer
												width="100%"
												height="100%"
												controls
												url={videoUrl}
											/>
										</div>
									</LazyLoad>
								)}
							</>
						)}
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default ExploreSection;
