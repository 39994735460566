import { Link } from "gatsby";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { FaCheck } from "react-icons/fa";

const CertificationPricing = ({
	heading,
	tagline,
	content,
	monthlyPricing,
	yearlyPricing,
	pricingCard1,
	pricingCard2,
	pricingCard3,
}) => {
	const [selectedTab, setSelectedTab] = useState("monthly");
	const [monthlyFeatures, setMonthlyFeatures] = useState([]);
	const [yearlyFeatures, setYearlyFeatures] = useState([]);
	const [numOfItemsToShow, setNumberOfItems] = useState(6);
	const [numOfItemsToShowYearly, setNumberOfItemsShowYearly] = useState(6);
	const [hoveredCard, setHoveredCard] = useState(null);

	const handleMouseEnter = (card) => setHoveredCard(card);
	const handleMouseLeave = () => setHoveredCard(null);

	return (
		<section className="d-flex align-items-center py-lg-8 py-4">
			<Container>
				<Row className={`justify-content-center`}>
					<Col lg={7} className={`mb-4 mb-lg-0 text-center`}>
						<span className="text-light-black">{tagline}</span>
						<h1 className="text-light-black py-2">{heading}</h1>
						<div
							className="text-light-black"
							dangerouslySetInnerHTML={{ __html: content }}
						></div>
					</Col>
				</Row>
				<Row className=" pt-lg-5 g-4">
					<Col md={6} lg={4}>
						<div
							onMouseEnter={() => handleMouseEnter("one")}
							onMouseLeave={handleMouseLeave}
							className="p-4 h-100"
							style={{
								border:
									hoveredCard === "one"
										? "1px solid #42944D"
										: "1px solid rgba(37, 37, 37, 0.10)",
								borderRadius: "24px",
								background: hoveredCard === "one" ? "#FAFFFA" : "white",
							}}
						>
							<div
								style={{
									borderBottom:
										hoveredCard === "one"
											? "1px solid #42944D"
											: "1px solid #3F7A61",
								}}
								className="pb-4"
							>
								<h2
									className={`${
										hoveredCard === "one" ? "text-primary" : "text-pricing-card"
									} fs-4`}
								>
									{pricingCard1.heading}
								</h2>
								<p
									className={`${
										hoveredCard === "one" ? "text-primary" : "text-pricing-card"
									} mt-3`}
								>
									<span className="display-4 montserrat-bold">
										{pricingCard1.price}
									</span>
									<span className="fs-4 montserrat-bold">/yr</span>
								</p>
								<Button
									style={{ borderRadius: "12px" }}
									as={Link}
									to="/contact-us"
									size="sm"
									className="px-4 py-2  w-100 d-block montserrat-bold fs-6 mt-4 text-white"
									variant={`${
										hoveredCard === "one" ? "primary" : "pricing-card"
									}`}
								>
									{pricingCard1.buttonText}
								</Button>
							</div>
							<div className="pt-5">
								{pricingCard1.features.map((item, i) => (
									<div key={i} className="d-flex">
										{" "}
										{/* Assuming `item.id` is unique */}
										<FaCheck
											style={{ minWidth: "30px", width: "30px" }}
											className={`${
												hoveredCard === "one"
													? "text-primary"
													: "text-pricing-card"
											} me-2 mt-2`}
										/>
										<p>{item.feature}</p>{" "}
										{/* Assuming you want to display the name property */}
									</div>
								))}
							</div>
						</div>
					</Col>
					<Col md={6} lg={4}>
						<div
							onMouseEnter={() => handleMouseEnter("two")}
							onMouseLeave={handleMouseLeave}
							className="p-4 h-100"
							style={{
								border:
									hoveredCard === "two"
										? "1px solid #42944D"
										: "1px solid rgba(37, 37, 37, 0.10)",
								borderRadius: "24px",
								background: hoveredCard === "two" ? "#FAFFFA" : "white",
							}}
						>
							<div
								style={{
									borderBottom:
										hoveredCard === "two"
											? "1px solid #42944D"
											: "1px solid #3F7A61",
								}}
								className="pb-4"
							>
								<h2
									className={`${
										hoveredCard === "two" ? "text-primary" : "text-pricing-card"
									} fs-4`}
								>
									{pricingCard2.heading}
								</h2>
								<p
									className={`${
										hoveredCard === "two" ? "text-primary" : "text-pricing-card"
									} mt-3`}
								>
									<span className="display-4 montserrat-bold">
										{pricingCard2.price}
									</span>
									<span className="fs-4 montserrat-bold">/yr</span>
								</p>
								<Button
									style={{ borderRadius: "12px" }}
									as={Link}
									to="/contact-us"
									size="sm"
									className="px-4 py-2  w-100 d-block montserrat-bold fs-6 mt-4 text-white"
									variant={`${
										hoveredCard === "two" ? "primary" : "pricing-card"
									}`}
								>
									{pricingCard2.buttonText}
								</Button>
							</div>
							<div className="pt-5">
								{pricingCard2.features.map((item, i) => (
									<div key={i} className="d-flex">
										{" "}
										{/* Assuming `item.id` is unique */}
										<FaCheck
											style={{ minWidth: "30px", width: "30px" }}
											className={`${
												hoveredCard === "two"
													? "text-primary"
													: "text-pricing-card"
											} me-2 mt-2`}
										/>
										<p>{item.feature}</p>{" "}
										{/* Assuming you want to display the name property */}
									</div>
								))}
							</div>
						</div>
					</Col>
					<Col md={6} lg={4}>
						<div
							onMouseEnter={() => handleMouseEnter("Three")}
							onMouseLeave={handleMouseLeave}
							className="p-4 h-100"
							style={{
								border:
									hoveredCard === "Three"
										? "1px solid #42944D"
										: "1px solid rgba(37, 37, 37, 0.10)",
								borderRadius: "24px",
								background: hoveredCard === "Three" ? "#FAFFFA" : "white",
							}}
						>
							<div
								style={{
									borderBottom:
										hoveredCard === "Three"
											? "1px solid #42944D"
											: "1px solid #3F7A61",
								}}
								className="pb-4"
							>
								<h2
									className={`${
										hoveredCard === "Three"
											? "text-primary"
											: "text-pricing-card"
									} fs-4`}
								>
									{pricingCard3.heading}
								</h2>
								<p
									className={`${
										hoveredCard === "Three"
											? "text-primary"
											: "text-pricing-card"
									} mt-3`}
								>
									<span className="display-4 montserrat-bold">
										{pricingCard3.price}
									</span>
									<span className="fs-4 montserrat-bold">/yr</span>
								</p>
								<Button
									style={{ borderRadius: "12px" }}
									as={Link}
									to="/contact-us"
									size="sm"
									className="px-4 py-2  w-100 d-block montserrat-bold fs-6 mt-4 text-white"
									variant={`${
										hoveredCard === "Three" ? "primary" : "pricing-card"
									}`}
								>
									{pricingCard3.buttonText}
								</Button>
							</div>
							<div className="pt-5">
								{pricingCard3.features.map((item, i) => (
									<div key={i} className="d-flex">
										{" "}
										{/* Assuming `item.id` is unique */}
										<FaCheck
											style={{ minWidth: "30px", width: "30px" }}
											className={`${
												hoveredCard === "Three"
													? "text-primary"
													: "text-pricing-card"
											} me-2 mt-2`}
										/>
										<p>{item.feature}</p>{" "}
										{/* Assuming you want to display the name property */}
									</div>
								))}
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default CertificationPricing;
