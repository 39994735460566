import { graphql, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { Helmet } from "react-helmet";
import CertificationJourney from "../components/certification-journey";
import CertificationPricing from "../components/certification-pricing";
import CertificationSteps from "../components/certification-steps";
import ClientTestimonials from "../components/client-testimonials";
import ExploreSection from "../components/explore-section";
import HeroBanner from "../components/hero";
import Layout from "../components/layout";
import TwoColumnImageContent from "../components/two-column-image-content";

const CerificationProgramme = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "award-and-certification-programme" }) {
				certificationProgramme {
					certificationBannerSection {
						bannerSectionHeading
						bannerSectionContent
						bannerSectionBackground {
							node {
								altText
								sourceUrl
							}
						}
					}
					certificationTwoColumnImageContent {
						twoColumnHeading
						twoColumnTagline
						twoColumnContent
						twoColumnCta1 {
							title
							target
							url
						}
						twoColumnCta2 {
							title
							target
							url
						}
						twoColumnImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: AVIF
											quality: 30
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						twoColumnImageBackgroundColor
						twoColumnVideoYoutube
						twoColumnVideo {
							node {
								altText
								sourceUrl
								mediaItemUrl
							}
						}
					}
					certificationJourney {
						journeyHeading
						journeyTagline
						journeyBackground
						journeyImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: AVIF
											quality: 30
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						journeySteps {
							journeyStepTitle
							journeyStepContent
						}
						certificationSteps {
							certificationStepTitle
							certificationStepContent
							certificationStepIcon {
								node {
									altText
									sourceUrl
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: AVIF
												quality: 30
												transformOptions: { cropFocus: CENTER, fit: COVER }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
						}
					}
					certificationSection {
						certificationHeading
						certificationBackground {
							node {
								altText
								sourceUrl
							}
						}
						certificationCta {
							title
							target
							url
						}
						certificationItems {
							certificationItemColor
							certificationItemContent
							certificationItemBackgroundColor
							certificationItemLogo {
								node {
									altText
									sourceUrl
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: AVIF
												quality: 30
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
												width: 140
											)
										}
									}
								}
							}
						}
					}
					certificationPricingAndBenefits {
						certificationPricingHeading
						certificationPricingTagline
						certificationPricingContent
						pricingCard1 {
							buttonText
							heading
							price
							features {
								feature
							}
						}
						pricingCard2 {
							buttonText
							features {
								feature
							}
							heading
							price
						}
						pricingCard3 {
							buttonText
							features {
								feature
							}
							heading
							price
						}
					}
					certificationExploreSection {
						exploreHeading
						exploreContent
						exploreCta1 {
							title
							target
							url
						}
						exploreCta2 {
							target
							title
							url
						}
						exploreImage {
							node {
								altText
								sourceUrl
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: AVIF
											quality: 30
											transformOptions: { cropFocus: CENTER, fit: COVER }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						exploreVideoYoutube
						exploreVideo {
							node {
								altText
								sourceUrl
								mediaItemUrl
							}
						}
					}
					certificationClientTestimonials {
						selectClientTestimonials {
							nodes {
								... on WpReview {
									title
									date(formatString: "DD MMMM, YYYY")
									reviewTypes {
										nodes {
											taxonomyName
											termTaxonomyId
										}
									}
									reviewPostFields {
										reviewAuthor
										reviewAuthorDesignation
										reviewAuthorCompanyPicture {
											node {
												altText
												sourceUrl
												localFile {
													childImageSharp {
														gatsbyImageData(
															formats: AVIF
															quality: 30
															transformOptions: {
																cropFocus: CENTER
																fit: COVER
															}
															layout: CONSTRAINED
															placeholder: BLURRED
															width: 80
														)
													}
												}
											}
										}
										reviewQuote
										reviewStarSelect
										reviewAuthorProfilePicture {
											node {
												altText
												sourceUrl
												localFile {
													childImageSharp {
														gatsbyImageData(
															formats: AVIF
															quality: 30
															transformOptions: {
																cropFocus: CENTER
																fit: COVER
															}
															layout: CONSTRAINED
															placeholder: BLURRED
															width: 56
														)
													}
												}
											}
										}
									}
									terms {
										nodes {
											taxonomyName
											name
										}
									}
								}
							}
						}
					}
				}
				seoFields {
					localBusinessSchema
					metaDescription
					opengraphDescription
					opengraphTitle
					title
					image {
						node {
							altText
							sourceUrl
							mediaDetails {
								height
								width
							}
						}
					}
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const {
		pageData: { seoFields, certificationProgramme },
	} = data;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Award And Certification Programme",
				item: {
					url: `${siteUrl}/award-and-certification-programme`,
					id: `${siteUrl}/award-and-certification-programme`,
				},
			},
		],
	};
	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/award-and-certification-programme`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.mediaDetails.width}`,
							height: `${seoFields?.image?.node.mediaDetails.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<HeroBanner
					heading={
						certificationProgramme?.certificationBannerSection
							.bannerSectionHeading
					}
					content={
						certificationProgramme?.certificationBannerSection
							.bannerSectionContent
					}
					bgImage={
						certificationProgramme?.certificationBannerSection
							.bannerSectionBackground?.node?.sourceUrl
					}
					showUnderline={false}
					textAlign={"center"}
					columns={8}
				/>
				<TwoColumnImageContent
					heading={
						certificationProgramme?.certificationTwoColumnImageContent
							.twoColumnHeading
					}
					content={
						certificationProgramme?.certificationTwoColumnImageContent
							.twoColumnContent
					}
					tagline={
						certificationProgramme?.certificationTwoColumnImageContent
							.twoColumnTagline
					}
					btnCta1={
						certificationProgramme?.certificationTwoColumnImageContent
							.twoColumnCta1
					}
					image={
						certificationProgramme?.certificationTwoColumnImageContent
							.twoColumnImage?.node
					}
					bgColor={
						certificationProgramme?.certificationTwoColumnImageContent
							.twoColumnImageBackgroundColor
					}
					textColor={"#000"}
				/>
				<CertificationJourney
					heading={certificationProgramme?.certificationJourney?.journeyHeading}
					tagline={certificationProgramme?.certificationJourney?.journeyTagline}
					certificationSteps={
						certificationProgramme?.certificationJourney?.certificationSteps
					}
					journeySteps={
						certificationProgramme?.certificationJourney?.journeySteps
					}
					journeyImage={
						certificationProgramme?.certificationJourney?.journeyImage?.node
					}
					bgColor={
						certificationProgramme?.certificationJourney?.journeyBackground
					}
				/>
				<CertificationSteps
					heading={
						certificationProgramme?.certificationSection.certificationHeading
					}
					bgImage={
						certificationProgramme?.certificationSection.certificationBackground
							?.node.sourceUrl
					}
					btnCta1={
						certificationProgramme?.certificationSection.certificationCta
					}
					certificationItems={
						certificationProgramme?.certificationSection?.certificationItems
					}
				/>
				<CertificationPricing
					heading={
						certificationProgramme?.certificationPricingAndBenefits
							.certificationPricingHeading
					}
					content={
						certificationProgramme?.certificationPricingAndBenefits
							.certificationPricingContent
					}
					tagline={
						certificationProgramme?.certificationPricingAndBenefits
							.certificationPricingTagline
					}
					pricingCard1={
						certificationProgramme?.certificationPricingAndBenefits.pricingCard1
					}
					pricingCard2={
						certificationProgramme?.certificationPricingAndBenefits.pricingCard2
					}
					pricingCard3={
						certificationProgramme?.certificationPricingAndBenefits.pricingCard3
					}
				/>
				<ExploreSection
					heading={
						certificationProgramme?.certificationExploreSection.exploreHeading
					}
					content={
						certificationProgramme?.certificationExploreSection.exploreContent
					}
					btnCta1={
						certificationProgramme?.certificationExploreSection.exploreCta1
					}
					image={
						certificationProgramme?.certificationExploreSection.exploreImage
							?.node
					}
					video={
						certificationProgramme?.certificationExploreSection.exploreVideo
							?.node?.mediaItemUrl
					}
					videoUrl={
						certificationProgramme?.certificationExploreSection
							.exploreVideoYoutube
					}
				/>
				<ClientTestimonials
					testimonials={
						certificationProgramme?.certificationClientTestimonials
							.selectClientTestimonials.nodes
					}
				/>
			</Layout>
		</>
	);
};

export default CerificationProgramme;
