import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import bgImage from "../images/certification-bg.png";

const CertificationJourney = ({
	heading,
	tagline,
	certificationSteps,
	journeySteps,
	bgColor,
	journeyImage,
}) => {
	// State to track the currently hovered journey step index
	const [hoveredIndex, setHoveredIndex] = useState(0);

	return (
		<section className="py-8 two-column-wrapper" style={sectionStyle(bgColor)}>
			<Container>
				<Row className="pb-8">
					<Col lg={6} className={`mb-4 mb-lg-0 text-start`}>
						<span className="text-white">{tagline}</span>
						<h2 className="my-0 pt-2 text-white">{heading}</h2>
					</Col>
					<Col lg={6} className={`mb-4 mb-lg-0 text-start position-relative`}>
						{certificationSteps.length > 0 &&
							certificationSteps.map((item, i) => (
								<div
									className="d-flex pb-6 certification-item position-relative"
									key={i}
								>
									<GatsbyImage
										image={
											item.certificationStepIcon?.node?.localFile
												.childImageSharp.gatsbyImageData
										}
										alt={item.certificationStepIcon?.node?.altText}
										style={{ width: "52px", height: "52px" }}
									/>
									<div className="ms-4" style={{ flex: 1 }}>
										<h5 className="text-white">
											{item.certificationStepTitle}
										</h5>
										<div
											dangerouslySetInnerHTML={{
												__html: item.certificationStepContent,
											}}
											className="journey-content text-start text-white"
										></div>
									</div>
								</div>
							))}
					</Col>
				</Row>
				<Row className="pt-md-8 align-items-lg-center">
					<Col lg={6} className={`mb-4 mb-lg-0 text-start pe-md-4`}>
						{journeySteps.length > 0 &&
							journeySteps.map((item, i) => (
								<div
									className="pb-2"
									onMouseEnter={() => setHoveredIndex(i)}
									onMouseLeave={() => setHoveredIndex(0)}
								>
									<div
										className={`journey-step ps-4  pb-3 ${
											hoveredIndex === i ? "journey-step-selected" : ""
										}`}
										key={i}
										// Assuming first journey step should be default
									>
										<h5 className="text-white">{item.journeyStepTitle}</h5>
										<div
											dangerouslySetInnerHTML={{
												__html: item.journeyStepContent,
											}}
											className="journey-content text-start text-white"
										></div>
									</div>
								</div>
							))}
					</Col>
					<Col lg={6} className={`mb-4 mb-lg-0 text-start position-relative`}>
						<GatsbyImage
							image={journeyImage.localFile.childImageSharp.gatsbyImageData}
							alt={journeyImage.altText}
							className="rounded-5 object-fit-cover border border-2 border-white main-image"
							style={{ minHeight: "400px" }}
						/>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export const sectionStyle = (bgColor) => ({
	background: `url(${bgImage}) no-repeat`,
	backgroundColor: bgColor,
	backgroundPosition: "top 40% left",
	backgroundSize: "40%",
});

export default CertificationJourney;
